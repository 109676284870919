const local = 2; // 1生产环境  2测试环境
const signUrlObject = {
  prod: "https://ywy.xjcec.com", // 生产环境 勿改
  // 'pre':'https://prexjcec.xjcec.com/',//pre环境
  test: "https://testywy.xjcec.com", // 测试环境  勿改
  pre: "https://ywy.xjcec.com", //pre环境
  dev: "https://testywy.xjcec.com", // 本地开发环境
};
const envNameList = () => Object.keys(signUrlObject);
// 基础url
exports.signBaseUrl =
  signUrlObject[process.env.VUE_APP_EDITION || envNameList()[local - 1]]; 
